import { tabClick } from 'store/search/searchActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitTabClickEvent = ({ payload }) => {
  return createEvent({
    type: EventType.TAB_CLICK,
    payload: {
      label: payload?.itemText,
      name: payload?.itemName,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [tabClick]: emitTabClickEvent };
